import Splide from '@splidejs/splide';
import images from './importImages';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Verifica la lista di immagini importate (debug)
// console.log(images);

// Utilizza una delle immagini importate
const axaLogo = images['../img/axa_logo.png'] ? images['../img/axa_logo.png'].default : '';

// Esempio di utilizzo in JavaScript (opzionale, solo per debug)
if (axaLogo) {
    const imgElement = document.createElement('img');
    imgElement.src = axaLogo;
    //document.body.appendChild(imgElement);
}

/**
 * Initializes the carousels using Splide.
 */
function initSplideCarousels() {
    const carousel1 = document.getElementById('splideCarousel');
    const i18nOpts = {
        prev: 'Diapositiva precedente',
        next: 'Diapositiva successiva',
        first: 'Prima diapositiva',
        last: 'Ultima diapositiva',
        slideX: 'Vai alla slide %s',
        pageX: 'Vai alla pagina %s',
        play: 'Inizia autoplay',
        pause: 'Pausa autoplay',
        select: 'Seleziona diapositiva',
        slide: 'Diapositiva',
        slideLabel: '%s di %s',
    }
    if (carousel1) {
        new Splide('#splideCarousel', {
            rewind: true,
            pagination: false,
            gap: "1em",
            focus: "center",
            fixedWidth: "586px",
            type: "loop",
            keyboard: "true",
            paginationKeyboard: "true",
            breakpoints: {
                640: {
                    perPage: 1, arrows: false, pagination: true, fixedWidth: "100vw", height: "450px",
                },
            },
            i18n: i18nOpts
        }).mount();
    }
    const carousel2 = document.getElementById('splideCarouselIcons');
    if (carousel2) {
        new Splide('#splideCarouselIcons', {
            arrows: false,
            perPage: 4,
            gap: "1.5em",
            pagination: false, // Disables the dots under the slider
            controls: false,
            breakpoints: {
                768: {
                    perPage: 1,
                    height: "350px",
                    pagination: true,

                }, 992: {
                    perPage: 2,
                    height: "350px",
                    pagination: true,

                },
            },
            i18n: i18nOpts
        }).mount();
    }
}

/**
 * Initializes the sticky bar.
 * Adds a scroll event listener to the document to toggle the visibility
 */
function initStickyBar() {
    var stickyBar = document.getElementById('stickyBar');
    if (stickyBar) {
        document.addEventListener('scroll', function () {
            if (window.scrollY > 10) {
                stickyBar.classList.add('visible');
            } else {
                stickyBar.classList.remove('visible');
            }
        });
    }
}

/**
 * Ensures that focused elements are visible above the sticky bar.
 */
function ensureFocusVisibility() {
    const stickyBar = document.getElementById('stickyBar');

    document.addEventListener('focusin', function (event) {
        let focusedElement = document.activeElement;
        if (focusedElement.id === 'axaModal') {
            // Per prevenire che scrolli ad ogni azione quando il modale è attivo
            // (il suo bottom verifica sempre la condizione)
            return;
        }
        let focusedElementRect = focusedElement.getBoundingClientRect();
        let stickyBarRect = stickyBar.getBoundingClientRect();

        if (focusedElementRect.bottom > stickyBarRect.top) {
            // Scrolla l'elemento in focus in vista se è nascosto dalla sticky bar
            window.scrollBy(0, focusedElementRect.bottom - stickyBarRect.top + 10);
        }
    });
}

/**
 * Automatically populate the hidden input 'fromButton' with the ID of the button
 * that triggered the modal when the AXA modal is shown.
 */
function initAxaModal() {
    var axaModal = document.getElementById('axaModal');
    if (axaModal) {
        axaModal.addEventListener('show.bs.modal', function (event) {
            let button = event.relatedTarget; // Button that triggered the modal
            let buttonId = button.id;
            let modal = this;
            // Deselect all radio buttons
            let radioButtons = modal.querySelectorAll('input[name="clientOption"]');
            radioButtons.forEach(function (radio) {
                radio.checked = false;
            });
            modal.querySelector('input[name="fromButton"]').value = buttonId;
        });
    }
}

/**
 * These two functions handle the submission of the AXA modal form. Retrieves the value of the hidden input
 * and the selected radio button, then redirects the user to different pages accordingly, for AXA or for MPS
 * window.open has been used in place of window.href to have links open in a new tab.
 */
function handleAXA(fromButtonValue, clientOptionValue) {
    var targetUrl;
    switch (fromButtonValue) {
        case 'pillarsButton':
        case 'heroButton':
        case 'stickyButton':
            targetUrl = 'https://www.axa.it/soluzioni-per-le-pmi';
            break;
        case 'industriaButton':
            targetUrl = 'https://www.axa.it/artigiani-e-commercianti';
            break;
        case 'commercioButton':
            targetUrl = 'https://www.axa.it/artigiani-e-commercianti';
            break;
        case 'turismoButton':
            targetUrl = 'https://www.axa.it/strutture-ricettive';
            break;
        case 'ristorazioneButton':
            targetUrl = 'https://www.axa.it/ristoranti-bar';
            break;
        case 'agricolturaButton':
            targetUrl = 'https://www.axa.it/assicurazione-agricola';
            break;
        case 'serviziButton':
            targetUrl = 'https://www.axa.it/smart-working';
            break;
        default:
            targetUrl = 'https://www.axa.it/soluzioni-per-le-pmi';
    }
    trackingModalFormSubmit(clientOptionValue, targetUrl);
    window.open(targetUrl, '_blank');
}

function handleMPS(fromButtonValue, clientOptionValue) {
    var targetUrl;
    switch (fromButtonValue) {
        case 'pillarsButton':
        case 'heroButton':
        case 'stickyButton':
            targetUrl = 'https://www.axa-mps.it/la-soluzione-per-il-tuo-settore-e-per-la-tua-attivita';
            break;
        case 'industriaButton':
            targetUrl = 'https://www.axa-mps.it/protezione-business-industria-e-artigianato';
            break;
        case 'commercioButton':
            targetUrl = 'https://www.axa-mps.it/protezione-business-commercio';
            break;
        case 'turismoButton':
            targetUrl = 'https://www.axa-mps.it/protezione-business-turismo-e-ristorazione';
            break;
        case 'ristorazioneButton':
            targetUrl = 'https://www.axa-mps.it/protezione-business-turismo-e-ristorazione';
            break;
        case 'agricolturaButton':
            targetUrl = 'https://www.axa-mps.it/protezione-business-agricoltura';
            break;
        case 'serviziButton':
            targetUrl = 'https://www.axa-mps.it/protezione-business-servizi';
            break;
        default:
            targetUrl = 'https://www.axa-mps.it/la-soluzione-per-il-tuo-settore-e-per-la-tua-attivita';
    }
    trackingModalFormSubmit(clientOptionValue, targetUrl);
    window.open(targetUrl, '_blank');
}


/**
 * Handles the submission of the AXA modal form. Retrieves the value of the hidden input
 * and the selected radio button, then redirects the user to different pages accordingly.
 */
function handleAxaModalFormSubmit() {
    let form = document.getElementById('axaModalForm');

    if (form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            let fromButtonValue = document.querySelector('input[name="fromButton"]').value;
            let selectedOption = document.querySelector('input[name="clientOption"]:checked');

            if (selectedOption) {
                let clientOptionValue = selectedOption.value;
                // Handle redirects based on the selected option
                if (clientOptionValue === 'axa' || clientOptionValue === 'not_client') {
                    handleAXA(fromButtonValue, clientOptionValue);
                } else if (clientOptionValue === 'mps') {
                    handleMPS(fromButtonValue, clientOptionValue);
                }
            } else {
                alert('Selezionare un\'opzione per procedere');
            }
        });
    }
}

/**
 * Handles the youtube modal to change the video dynamically
 */
function initYouTubeModal() {
    const youtubeModal = document.getElementById('youtubeModal');
    if (youtubeModal) {
        youtubeModal.addEventListener('show.bs.modal', function (event) {
            console.log("showing yt")
            const button = event.relatedTarget;
            // Extract the full YouTube URL from the data-yt attribute
            const videoSrc = button.getAttribute('data-yt');
            // Find the iframe inside the modal
            const iframe = youtubeModal.querySelector('iframe');
            // Update the iframe's src attribute with the full URL
            iframe.src = videoSrc + '&controls=0';
        });
        youtubeModal.addEventListener('hidden.bs.modal', function () {
            // Reset the iframe src to stop the video when the modal is hidden
            const iframe = youtubeModal.querySelector('iframe');
            iframe.src = '';
        });
    }
}


/**
 * @function showPrivacyCenter
 * @description This function checks if the `tC.privacyCenter` object is defined. If it is defined,
 *              it triggers the `showPrivacyCenter()` method of `tC.privacyCenter`. The function is
 *              bound to the button with the ID `rivediScelte`.
 * @returns {boolean} Returns false to prevent the default button action.
 */
function showPrivacyCenter() {
    if (typeof tC !== 'undefined' && typeof tC.privacyCenter !== 'undefined') {
        tC.privacyCenter.showPrivacyCenter();
    }
    return false;
}

// function initPrivacyCenter() {
//     // Add event listener to the button with ID 'rivediScelte'
//     document.getElementById('rivediScelte').addEventListener('click', showPrivacyCenter);
//
// }


/**
 * @function getDeviceType
 * @description Determines the type of device the user is using: 'Mobile', 'Tablet', or 'Desktop'.
 * @returns {string} The type of device ('Mobile', 'Tablet', 'Desktop').
 */

// Function to dynamically load an external script
function loadScript(src, callback) {
    var script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    script.onload = function () {
        callback(null, script);
    };
    script.onerror = function () {
        callback(new Error("Failed to load script: " + src));
    };
    document.head.appendChild(script);
}

function trackingModalFormSubmit(radio, url) {
    let clickName;
    // Switch case to determine the string to pass as click_name based on the value of radio
    switch (radio) {
        case 'axa':
            clickName = 'ottieni consulenza cliente Axa';
            break;
        case 'mps':
            clickName = 'ottieni consulenza MPS';
            break;
        case 'no_client':
            clickName = 'ottieni consulenza no cliente';
            break;
        default:
            clickName = 'ottieni consulenza no cliente';
    }
    tc_events_global(this, 'xt_click', {
        'level_2_number': 'imprese',
        'click_name': clickName,
        'click_type': url,
        'ga4_event': 'click_consulenza'
    });
}

// Function to determine device type
function getDeviceType() {
    var userAgent = navigator.userAgent.toLowerCase();
    var isMobile = /iphone|ipod|android.*mobile|windows phone|blackberry/i.test(userAgent);
    var isTablet = /ipad|android(?!.*mobile)|tablet|kindle|silk/i.test(userAgent);
    if (isMobile) {
        return 'mobile';
    } else if (isTablet) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}

/**
 * Initializes tracking for the development environment.
 * Dynamically loads several tracking scripts (from the TagCommander CDN)
 * for development purposes. It sets up the `tc_vars` object, which holds tracking
 * variables such as the page name, environment (preprod), device type, and template.
 *
 * If any script fails to load, an error is logged to the console.
 */
function initTrackingDev() {
    // Dynamically load the external script
    loadScript('https://cdn.tagcommander.com/4389/uat/tc_Axa1_New.js', function (error, script) {
        if (error) {
            console.error(error);
        }
    });
    window.tc_vars = {
        page_name: document.title,
        env_work: "preprod",
        env_channel: getDeviceType(),
        env_template: "AXA_Imprese",
    };
    loadScript('https://cdn.tagcommander.com/4389/uat/tc_Axa3_New.js', function (error, script) {
        if (error) {
            console.error(error);
        }
    });
    loadScript('https://cdn.tagcommander.com/4389/uat/tc_Axa5_New.js', function (error, script) {
        if (error) {
            console.error(error);
        }
    });
}

/**
 * Initializes tracking for the production environment like the function above.
 * If any script fails to load, an error is logged to the console.
 */
function initTrackingProd() {
    // Dynamically load the external script
    loadScript('https://cdn.tagcommander.com/4389/tc_Axa1_New.js', function (error, script) {
        if (error) {
            console.error(error);
        }
    });
    window.tc_vars = {
        page_name: document.title,
        env_work: "production",
        env_channel: getDeviceType(),
        env_template: "AXA_Imprese",
    };
    loadScript('https://cdn.tagcommander.com/4389/tc_Axa3_New.js', function (error, script) {
        if (error) {
            console.error(error);
        }
    });
    loadScript('https://cdn.tagcommander.com/4389/tc_Axa5_New.js', function (error, script) {
        if (error) {
            console.error(error);
        }
    });
}

/**
 * This function makes it so if the user presses enter on the element, a click on that element is simulated
 *
 */
function initEnterBehavior() {
    document.addEventListener('keydown', (event) => {
        // Check if the ENTER key is pressed
        if (event.key === 'Enter') {
            console.log("pressed enter")
            // Get the currently focused element
            const focusedElement = document.activeElement;
            console.log(focusedElement)
            focusedElement.click();
            event.preventDefault(); // Prevent any default behavior associated with ENTER
        }
    });
}

/**
 * Wraps the functions to be executed when the DOM content is fully loaded.
 */
function onDOMLoaded() {
    initSplideCarousels(); // Inizializza gli slider
    initStickyBar();    // Inizializza la StickyBar
    ensureFocusVisibility(); // Inizializza lo scroll per gli elementi in focus
    initAxaModal();// Inizializza il modale aggiungendo evento on show
    initYouTubeModal(); // Inizializza il modale dinamicizzando il video
    // initPrivacyCenter() // Inizializza il bottone privacy nel footer

    // Carica dinamicamente gli script in base all'ambiente
    if (import.meta.env.VITE_APP_ENV === 'production') {
        initTrackingProd();
    } else {
        initTrackingDev();
    }
    handleAxaModalFormSubmit(); // Aggiunge logica al form del modale
    initEnterBehavior();
}


// Ensure the DOM content is loaded before executing some functions
document.addEventListener('DOMContentLoaded', onDOMLoaded);
